import { useState } from 'react';
import {
  Box,
  AlertDescription,
  Alert,
  AlertIcon,
  AlertTitle,
  Divider,
  Input,
  InputLeftAddon,
  InputGroup,
  HStack,
  Button,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  useClipboard,
  Text,
  Select,
  useToast,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';

import createMember from '../services/createMember';
// import DataContext from '../context/DataContext';

const AddUserModal = ({ partnerUid, businessName }) => {
  const [formData, setFormData] = useState({
    createdAt: new Date().toString(),
    lastLogin: '',
    businessName,
    partnerUid,
    firstName: '',
    lastName: '',
    title: '',
    email: '',
    password: '',
    phone: '',
    cell: '',
    admin: false,
    active: true,
    // memberUid: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isComplete, setIsComplete] = useState(false);
  const { onCopy, value, setValue, hasCopied } = useClipboard('');
  const toast = useToast();

  const createAccount = async () => {
    setIsSubmitting(true);

    const response = await createMember(formData);
    // console.log(response);

    if (!response?.success) {
      const message = response?.error?.message || 'Please try again later';
      // console.log('error response', response?.error);
      // window.alert('Could not create member. '+message);
      toast({
        title: 'Could not create member',
        description: message,
        status: 'error',
        isClosable: true,
      });
      setIsSubmitting(false);
      setIsComplete(false);
    } else {
      setValue(
        'Portal: https://partner.eeze.co/login Email: ' + formData?.email + ' Password: ' + formData?.password
      );
      setIsSubmitting(false);
      setIsComplete(true);
    }
  };

  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: boolean ?? value,
    }));
  };

  const renderBody = () => {
    return (
      <>
        <Box as="b">
          User Information{' '}
          {(!formData.firstName || !formData.lastName) && (
            <span style={{ color: 'red' }}>*</span>
          )}
        </Box>
        <HStack>
          <Box w="50%">
            <Input
              type="text"
              id="firstName"
              placeholder="First Name"
              value={formData.firstName}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </Box>
          <Box w="50%">
            <Input
              type="text"
              id="lastName"
              placeholder="Last Name"
              value={formData.lastName}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </Box>
        </HStack>

        <Box w="30%" as="b">
          Title / Position
        </Box>
        <HStack>
          <Box w="100%">
            <Input
              id="title"
              type="text"
              value={formData.title}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </Box>
        </HStack>
        <Box w="30%" as="b">
          Login Email{' '}
          {!formData.email && <span style={{ color: 'red' }}>*</span>}
        </Box>
        <HStack>
          <Box w="100%">
            <InputGroup>
              <Input
                type="email"
                id="email"
                value={formData.email}
                onChange={onChange}
                disabled={isSubmitting}
              />
            </InputGroup>
          </Box>
        </HStack>
        <Box w="30%" as="b">
          Password{' '}
          {!formData.password && <span style={{ color: 'red' }}>*</span>}
        </Box>
        <HStack>
          <Box w="100%">
            <InputGroup>
              <Input
                type="text"
                id="password"
                value={formData.password}
                onChange={onChange}
                disabled={isSubmitting}
              />
            </InputGroup>
          </Box>
        </HStack>
        <Text
          align="center"
          decoration="italic"
          fontSize="sm"
          color="lightgrey"
        >
          Must be at least 7 characters
        </Text>
        <Box as="b">Business Phone</Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftAddon children="+1" />
            <Input
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              type="tel"
              id="phone"
              value={formData.phone}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </InputGroup>
        </Box>
        <Box w="30%" as="b">
          Cell Phone
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftAddon children="+1" />
            <Input
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              type="tel"
              id="cell"
              value={formData.cell}
              onChange={onChange}
              disabled={isSubmitting}
            />
          </InputGroup>
        </Box>
        <HStack justify="space-between" mt={4}>
          <Text>Is Admin (can view/edit users)</Text>

          <Select
            w="100px"
            id="admin"
            value={formData.admin}
            onChange={onChange}
            disabled={isSubmitting}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </Select>
        </HStack>
        <br />

        {formData.firstName &&
        formData.lastName &&
        formData.email &&
        formData.password &&
        formData.password.length > 7 ? (
          <Button
            colorScheme="primary"
            w="100%"
            size="lg"
            // mb="5px"
            // mt="14px"
            isLoading={isSubmitting ? true : false}
            disabled={isSubmitting ? true : false}
            onClick={createAccount}
          >
            Create Member Account
          </Button>
        ) : (
          <Button
            // type="submit"
            size="lg"
            colorScheme="primary"
            w="100%"
            // mb="5px"
            // mt="14px"
            disabled
          >
            Create Member Account
          </Button>
        )}
      </>
    );
  };
  // console.log(formData);
  return (
    <>
      <ModalHeader>Add a user to this portal</ModalHeader>
      <ModalCloseButton />
      <Divider borderColor="primary.500" borderWidth={1} />
      <ModalBody pb={6}>
        <Text fontWeight="bold" fontSize="lg">
          {businessName}
        </Text>
        <br />
        {!isComplete ? (
          <>{renderBody()}</>
        ) : (
          <>
            <Alert
              status="success"
              variant="subtle"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              textAlign="center"
              my={2}
              py={5}
            >
              <AlertIcon boxSize="30px" />
              <AlertTitle mt={4} mb={1} fontSize="lg">
                User created!
              </AlertTitle>
              <AlertDescription>
                The new user {formData.firstName} {formData.lastName} can log
                into this partner portal using credentials:
              </AlertDescription>
              <br />
              <Box bg="gray.50" py={2} w="100%">
                <Text>Portal: https://partner.eeze.co/login</Text>
                <Text>Email: {formData.email}</Text>
                <Text>Password: {formData.password}</Text>
              </Box>
              <Button
                mt={2}
                mb={4}
                onClick={onCopy}
                w="full"
              >
                {hasCopied ? 'Copied!' : 'Copy Credentials'}
              </Button>
              <AlertDescription>
                To change the password,<br/>
                use the Forgot Password link before login
              </AlertDescription>
            </Alert>
          </>
        )}
        <br />
        <br />
        <br />
      </ModalBody>
    </>
  );
};

export default AddUserModal;
