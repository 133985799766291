import { useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection } from 'firebase/firestore';
import {
  Button,
  Box,
  Container,
  Center,
  Select,
  Spinner,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  Stack,
  Text,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react';

import { db, isDev } from '../constants';
import CARS from '../services/carInfo';
import decodeVIN from '../services/decodeVIN';

function CreateVehicle(props) {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [decoding, setDecoding] = useState(false);
  const [listingUid, setListingUid] = useState();
  const [formData, setFormData] = useState({
    odometerType: 'km',
    currency: 'CAD',
  });
  const {user, partnerData} = props;

  const createListing = async () => {
    // create vehicle with data in forms
    setLoading(true)
    try {
      const docRef = await addDoc(collection(db, 'listings'), {
        ...formData,
        createdAt: new Date().toString(),
        // affiliateRef: userData?.affiliateRef || '',
        garageRef: partnerData?.refSlug,
        sellerUid: user.uid,
        language: 'EN',
        sellerName: user.firstName + ' ' + user.lastName.substring(0, 1) + '.',
        sellerFullName: user.firstName + ' ' + user.lastName,
        sellerEmail: user?.email,
        sellerCell: user?.cell,
        sellerLocation: user.address
          ? user?.address?.city + ', ' + user.address?.province.toUpperCase()
          : '',
        sellerPhotoURL: user?.photoURL || '',
        status: 'PRIVATE', // vehicleOnly ? LISTING.STATUS.PRIVATE : LISTING.STATUS.DRAFT,
        type: 'VEHICLE', // LISTING.TYPE.VEHICLE,
        province: user.address ? user.address?.province.toLowerCase() : 'on',
      });
      setListingUid(docRef.id);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      toast({
        title: 'Could not create vehicle',
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onChange = e => {
    let boolean = null;
    let value = e.target.value;

    if (e.target.value === 'true') {
      boolean = true;
    }
    if (e.target.value === 'false') {
      boolean = false;
    }

    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: boolean ?? value,
    }));
  };

  const onChangeVIN = e => {
    if (e.target.value !== '') {
      const regEx = /^[0-9a-zA-Z]+$/;
      if (!e.target.value.match(regEx)) return;
      if (e.target.value.includes(' ')) return;
      if (e.target.value.includes('I')) return;
      if (e.target.value.includes('i')) return;
      if (e.target.value.includes('O')) return;
      if (e.target.value.includes('o')) return;
      if (e.target.value.includes('Q')) return;
      if (e.target.value.includes('q')) return;
      if (e.target.value.length > 17) return;
    }
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value.toUpperCase(),
    }));
  };

  const insertRandomVIN = () => {
    const index = Math.floor(Math.random() * CARS.RANDOM_VINS.length);
    setFormData(prevState => ({
      ...prevState,
      vin: CARS.RANDOM_VINS[index],
    }));
  };

  const onClickDecodeVIN = async (vin, odometer) => {
    setDecoding(true);

    try {
      const decodeResponse = await decodeVIN(vin);
      // console.log('response from decode', decodeResponse);

      if (!decodeResponse.success) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.status === 'NOT_FOUND') {
        toast({
          title:
            'VIN Decode fail, please verify or enter information manually. ' +
            decodeResponse.message,
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      if (!decodeResponse.data.years) {
        toast({
          title:
            'VIN Decode fail, please verify and retry or enter information manually',
          status: 'warning',
          isClosable: true,
        });
        setDecoding(false);
        return;
      }
      const title = `${decodeResponse.data.years[0]?.year} ${decodeResponse.data.make?.name} ${decodeResponse.data.model?.name}`;
      setFormData(prevState => ({
        ...prevState,
        fullVehicle: decodeResponse.data,
        year: decodeResponse.data.years[0]?.year,
        make: decodeResponse.data.make?.name,
        model: decodeResponse.data.model?.name,
        title,
        vin,
        odometer: odometer ? odometer : '',
        colorExterior: '',
        colorInterior: '',
        style: '',
      }));
      toast({
        title: 'VIN Decoded!',
        status: 'success',
        isClosable: true,
      });
      setDecoding(false);
    } catch (e) {
      //  console.log(e);
      toast({
        title: 'VIN Decode fail, please verify and retry',
        status: 'error',
        isClosable: true,
      });
      setDecoding(false);
    }
  };

  let decodeIsDisabled = true;
  if (formData?.vin?.length === 17) {
    decodeIsDisabled = false;
  }

  let descriptionIsComplete = true;
  if (!formData.year) {
    descriptionIsComplete = false;
  }
  if (!formData.make) {
    descriptionIsComplete = false;
  }
  if (!formData.model) {
    descriptionIsComplete = false;
  }
  if (!formData.style) {
    descriptionIsComplete = false;
  }
  if (!formData.odometer) {
    descriptionIsComplete = false;
  }
  if (!formData.title) {
    descriptionIsComplete = false;
  }

  const renderDescription = () => {
    if (decoding) {
      return (
        <Box minHeight="40vh">
          <Center m={10} p={10}>
            <Spinner size="xl" color="primary.500" />
          </Center>
        </Box>
      );
    }

    return (
      <Box minHeight="40vh">
        {!formData.fullVehicle && (
          <>
            <FormControl mt={5}>
              <Center textAlign="center" my={10}>
                Begin by entering the VIN
              </Center>
              <Stack direction="row" align="center">
                <FormLabel m={0}>
                  VIN
                  {!formData.vin && <span style={{ color: 'red' }}> *</span>}
                </FormLabel>
              </Stack>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChangeVIN}
                disabled={formData.fullVehicle ? true : false}
              />
            </FormControl>

            {isDev && !formData.vin && (
              <Button
                onClick={insertRandomVIN}
                mt={10}
                size="sm"
                variant="link"
                color="lightgray"
              >
                <i>DEV Insert Random VIN</i>
              </Button>
            )}
          </>
        )}

        {formData.vin && !formData.make && !formData.model && (
          <Button
            onClick={() => onClickDecodeVIN(formData.vin)}
            colorScheme="blue"
            w="full"
            mt={2}
            disabled={decodeIsDisabled}
          >
            DECODE
          </Button>
        )}

        {formData.fullVehicle && (
          <>
            <FormLabel m={0} w="50%">
              VIN
            </FormLabel>
            <InputGroup>
              <Input
                id="vin"
                type="text"
                value={formData.vin}
                onChange={onChange}
                disabled
              />
            </InputGroup>

            <FormControl mt="2%">
              <Stack direction="row" w="full">
                <FormLabel m={0} w="50%">
                  Year
                </FormLabel>
                <FormLabel m={0}>Make</FormLabel>
              </Stack>
              <InputGroup>
                <Input
                  id="year"
                  // type="text"
                  value={formData.year}
                  // onChange={onChange}
                  disabled
                />
                <Input
                  id="make"
                  type="text"
                  value={formData.make}
                  onChange={onChange}
                  disabled
                  list="makes-list"
                />
              </InputGroup>
              <FormLabel m={0} w="50%">
                Model
              </FormLabel>
              <InputGroup>
                <Input
                  id="model"
                  type="text"
                  value={formData.model}
                  onChange={onChange}
                  disabled
                />
              </InputGroup>
            </FormControl>

            <FormControl mt="2%" color={!formData.style ? 'red' : ''}>
              <FormLabel m={0}>
                Trim
                {!formData.style && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                {formData.trim && (
                  <Input
                    id="style"
                    placeholder="Or enter manually"
                    type="text"
                    value={formData.trim}
                    onChange={onChange}
                    disabled={listingUid}
                  />
                )}
                {formData.fullVehicle?.years && (
                  <Select
                    id="style"
                    value={formData.style}
                    onChange={onChange}
                    disabled={listingUid}
                  >
                    <option value="">Select</option>
                    {formData.fullVehicle.years[0].styles.map((style, i) => (
                      <option key={i} value={style.name}>
                        {style.name}
                      </option>
                    ))}
                  </Select>
                )}
              </InputGroup>
            </FormControl>

            <FormControl mt={'2%'} mb={2} color={!formData.style ? 'red' : ''}>
              <FormLabel m={0}>
                Odometer
                {!formData.odometer && <span style={{ color: 'red' }}> *</span>}
              </FormLabel>
              <InputGroup>
                <Input
                  id="odometer"
                  type="number"
                  w="50%"
                  value={formData.odometer}
                  onChange={onChange}
                  disabled={listingUid}
                  // onBlur={onBlurOdometer}
                />
                <Input
                  id="odometerType"
                  // type="number"
                  w="50%"
                  value={formData.odometerType}
                  onChange={onChange}
                  disabled
                  // onBlur={onBlurOdometer}
                />
              </InputGroup>
            </FormControl>
          </>
        )}
      </Box>
    );
  };

  if (loading) {
    return (
      <Box minHeight="40vh">
        <Center m={10} p={10}>
          <Spinner size="xl" color="primary.500" />
        </Center>
      </Box>
    );
  }
  return (
    <>
      {/* <Header back previousScreen /> */}
      {/* <AppBody> */}
        <Heading size="sm" textAlign="center">
          Add Vehicle for
        </Heading>
        <Heading size="lg" textAlign="center">
          {user?.firstName} {user?.lastName}
        </Heading>
        <Heading size="md" textAlign="center">
          {formData?.title}
        </Heading>

        <Container maxW={'2xl'} p={0} mt={2}>
          {renderDescription()}


          {/* <ButtonGroup my={4} alignItems="center" w="100%"> */}
            {/* <Button
              variant="outline"
              size={'sm'}
              onClick={onClickCancel}
              isLoading={isSavingForm}
              flex={1}
            >
              Cancel
            </Button> */}
            {descriptionIsComplete && !listingUid && (
              <>
                <Button
                  onClick={createListing}
                  w='full'
                  mt={5}
                  variant="solid"
                  colorScheme="primary"
                >
                  CREATE VEHICLE
                </Button>
              </>
            )}
            {descriptionIsComplete && listingUid && (
              <>
              <Center align='center'>

<Text fontSize='lg'>Vehicle Created!</Text>
              </Center>
<ButtonGroup my={10}>


                <Button
                  w='full'
                  size='lg'
                  variant="solid"
                  colorScheme="primary"
                  onClick={() => navigate(`/listing/${listingUid}`)}
                >
                  Add Documents
                </Button>
                <Button
                  w='full'
                  size='lg'
                  variant="solid"
                  colorScheme="primary"
                  onClick={() => navigate('/warranty/' + listingUid)}
                >
                  Add Warranty
                </Button>
                </ButtonGroup>
              </>
            )}
        </Container>
    </>
  );
}

export default CreateVehicle;
