import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  Button,
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Input,
  Center,
  Divider,
  Flex,
  Heading,
  HStack,
  Stack,
  Text,
  Badge,
  useToast,
  IconButton,
} from '@chakra-ui/react';
import { ExternalLinkIcon, DeleteIcon } from '@chakra-ui/icons';
import { getDoc, doc, updateDoc } from 'firebase/firestore';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
// import { getAuth } from 'firebase/auth';

import DataContext from '../context/DataContext';
import ListingPageCarousel from '../components/ListingPageCarousel';
import { db, LISTING, masterSafetyPromotionEnabled } from '../constants';
import SpinnerEEZEPage from '../components/SpinnerEEZEPage';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import sendSafetyUploadNoticeToOwner from '../services/sendSafetyUploadNoticeToOwner';

// import odometerFormat from '../services/odometerFormat';

function Listing() {
  const [listing, setListing] = useState(null);
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);
  const safetyInput = useRef(null);
  const conditionInput = useRef(null);
  const carfaxInput = useRef(null);
  const invoiceInput = useRef(null);
  const toast = useToast();

  const navigate = useNavigate();
  const params = useParams();

  const { partnerData, userData } = useContext(DataContext);
  if (!partnerData) {
    // on first load, partnerData is not loaded yet
    // console.log('no datacontext')
    window.location.reload(false);
  }
  const { refSlug, uid } = partnerData;
  // console.log(useContext(DataContext))
  // console.log(partnerData)
  useEffect(() => {
    const fetchListing = async () => {
      const docRef = doc(db, 'listings', params.listingUid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setListing(docSnap.data());
        setLoading(false);
      } else {
        window.alert('Listing error');
        navigate(-1);
      }
    };

    fetchListing();
  }, [navigate, params]);

  if (loading) {
    return <SpinnerEEZEPage />;
  }

  const handleSafetyClick = () => {
    // safety test
    safetyInput.current.click();
  };
  const handleConditionClick = () => {
    // condition report
    conditionInput.current.click();
  };
  const handleCarfaxClick = () => {
    // carfax report
    carfaxInput.current.click();
  };
  const handleInvoiceClick = () => {
    // latest invoice
    invoiceInput.current.click();
  };

  const storeImage = async image => {
    return new Promise((resolve, reject) => {
      const storage = getStorage();
      const fileName = `vehicle-${Date.now()}`;

      const storageRef = ref(
        storage,
        'images/listings/' + listing.uid + '/' + fileName
      );

      const uploadTask = uploadBytesResumable(storageRef, image);

      uploadTask.on(
        'state_changed',
        snapshot => {
          // const progress =
          //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          // console.log('Upload is ' + progress + '% done');
          // switch (snapshot.state) {
          //   case 'paused':
          //     console.log('Upload is paused');
          //     break;
          //   case 'running':
          //     console.log('Upload is running');
          //     break;
          //   default:
          //     break;
          // }
        },
        error => {
          console.log(error);
          reject(false);
        },
        () => {
          // Handle successful uploads on complete
          getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const onChangeFileInput = async e => {
    // console.log(e)
    setUploading(true);
    await Promise.resolve(storeImage(e.target.files[0]))
      .then(fileName => {
        // console.log('upload done...', fileName);
        // setLoading(true);
        const thisListingRef = doc(db, 'listings/', listing?.uid);
        updateDoc(thisListingRef, {
          [e.target.id]: fileName,
          [e.target.id + 'UploadBy']: uid,
          [e.target.id + 'UploadAt']: new Date().toString(),
          garageRef: refSlug,
        });
        const newFormData = {
          ...listing,
          [e.target.id]: fileName,
          [e.target.id + 'UploadBy']: uid,
          [e.target.id + 'UploadAt']: new Date().toString(),
          garageRef: refSlug,
        };
        setListing(newFormData);
        toast({
          title: 'File uploaded',
          status: 'success',
          isClosable: true,
        });
        setUploading(false);

        // take special action if safety test warranty promo active, and another warranty is not active
        if (e.target.id === 'partnerSafetyTest') {
          if (
            (masterSafetyPromotionEnabled ||
              partnerData.safetyPromotionEnabled) &&
            !listing.warrantyActive
          ) {
            // if (listing.warrantySafetyPromo) {
            // send notification to customer, cc admin that we've received the safety and will activate their warranty
            // window.alert('send notice');
            // notify owner
            sendSafetyUploadNoticeToOwner({
              businessName: userData.businessName,
              listingName: listing.title,
              sellerCell: listing.sellerCell,
              sellerEmail: listing.sellerEmail.replace('+', '%2b'),
              sellerName: listing.sellerName,
            });
          }
        }
      })
      .catch(err => {
        console.log('error', err);
        setUploading(false);
        toast({
          title: 'Could not upload image',
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        // return listing[e.target.id];
      });
  };

  const carouselPhotos = [];
  if (listing?.listingProfilePhoto) {
    carouselPhotos.push(listing.listingProfilePhoto);
  }
  if (listing?.vehicleOdometerPhoto) {
    carouselPhotos.push(listing.vehicleOdometerPhoto);
  }
  // if (listing.vehicleLicensePhoto) {
  //   carouselPhotos.push(listing.vehicleLicensePhoto);
  // }
  // if (listing.vehicleVINPhoto) {
  //   carouselPhotos.push(listing.vehicleVINPhoto);
  // }
  // if (listing.listingOtherPhotos?.length !== 0) {
  if (listing?.listingOtherPhotos) {
    listing.listingOtherPhotos.map(photo => carouselPhotos.push(photo));
  }

  return (
    <>
      <Header back previousScreen />
      <AppBody>
        {listing.status === LISTING.STATUS.COMPLETED && (
          <>
            <Divider borderColor="primary.500" borderWidth={1} />
            <Center align="center">
              <Heading color={'primary.500'}>- SOLD -</Heading>
            </Center>
            <Divider borderColor="primary.500" borderWidth={1} />
          </>
        )}
        {carouselPhotos.length !== 0 && (
          <Flex m={0} p={0}>
            <ListingPageCarousel photos={carouselPhotos} />
          </Flex>
        )}

        <Stack>
          <Heading size="md" textAlign="center">
            {listing?.sellerFullName}
          </Heading>
          {/* <Text>{listing?.sellerLocation}</Text> */}
          <Heading size="lg" textAlign="center">
            {listing.title}
          </Heading>
          {listing?.vin && (
            <Heading size="md" textAlign="center">
              VIN {listing?.vin}
            </Heading>
          )}
          {listing.postedAt && (
            <HStack justify="space-between">
              <Text>EEZE ID: {listing?.eezeID.toUpperCase()}</Text>
              <Button
                colorScheme="primary"
                variant="link"
                size="sm"
                // as='u'
                rightIcon={<ExternalLinkIcon />}
                onClick={() =>
                  window.open(
                    'https://' + listing.shortUrl + '?ref=' + refSlug,
                    '_blank'
                  )
                }
              >
                {listing.shortUrl}
              </Button>
            </HStack>
          )}
          {/* <HStack justify="space-between" mb={2}>
            <Box>
              <Text>EEZE ID: {listing.eezeID.toUpperCase()}</Text>
              <Button
                colorScheme="primary"
                variant="link"
                as='u'
                rightIcon={<ExternalLinkIcon />}
                onClick={() =>
                  window.open('https://' + listing.shortUrl + '?ref='+refSlug, '_blank')
                }
              >
                Page: {listing.shortUrl}
              </Button>
              <Text>Status: {listing.status}</Text>
            </Box>

            <ButtonGroup>
              <Button
                rightIcon={<FaRegShareSquare />}
                // variant="outline"
                colorScheme="primary"
                // size="sm"
                onClick={onOpen}
                disabled={!listingIsActive}
              >
                Share listing
              </Button>
            </ButtonGroup>
          </HStack> */}
          <Divider borderColor="primary.500" borderWidth={1} />
          <br />
          <Center>
            <Text fontSize="xl" align="center">
              Add documents related to this vehicle
            </Text>
          </Center>

          {(masterSafetyPromotionEnabled ||
            partnerData.safetyPromotionEnabled) && (
            <>
              <Alert
                status={!listing.partnerSafetyTest ? 'info' : 'success'}
                variant="left-accent"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
              >
                <AlertIcon mr={0} />

                {!listing.partnerSafetyTest ? (
                  <>
                    <AlertTitle mt={1} mb={1} fontSize="MD">
                      PROMOTION ENABLED*
                    </AlertTitle>
                    <AlertDescription maxWidth="lg">
                      Upload a safety test or pre-sale inspection for your
                      customer to activate their FREE 90-day Powertrain Warranty
                    </AlertDescription>
                  </>
                ) : (
                  <>
                    <AlertTitle mt={1} mb={1} fontSize="MD">
                      THANK YOU
                    </AlertTitle>
                    <AlertDescription maxWidth="lg">
                      Safety test received! We are processing the warranty and
                      have notified the owner
                    </AlertDescription>
                  </>
                )}
              </Alert>
              <br />
            </>
          )}

          <Stack spacing={4} direction={'column'}>
            <HStack justify="space-between">
              <Text pt={2}>
                Safety Test{' '}
                {!listing.partnerSafetyTest && (masterSafetyPromotionEnabled ||
                  partnerData.safetyPromotionEnabled) && (
                  <Badge colorScheme="blue">* Promo</Badge>
                )}
              </Text>
              <Input
                type="file"
                id="partnerSafetyTest"
                ref={safetyInput}
                style={{ display: 'none' }}
                onChange={onChangeFileInput}
                accept=".jpg,.png,.jpeg,.pdf"
              />
              {listing.partnerSafetyTest ? (
                <>
                  {/* {listing.partnerSafetyTestUploadBy === partnerData.uid ? ( */}
                  <HStack w="50%">
                    <Button
                      variant="outline"
                      w="full"
                      colorScheme="primary"
                      rightIcon={<ExternalLinkIcon />}
                      onClick={() =>
                        window.open(listing.partnerSafetyTest, '_blank')
                      }
                    >
                      Click to view
                    </Button>

                    <IconButton
                      isLoading={uploading}
                      onClick={() => {
                        if (
                          !window.confirm(
                            'Delete and upload again, are you sure?'
                          )
                        ) {
                          return;
                        }
                        handleSafetyClick();
                      }}
                      colorScheme="primary"
                      size="sm"
                      aria-label="Delete"
                      icon={<DeleteIcon />}
                    />
                  </HStack>
                </>
              ) : (
                <Button
                  colorScheme="primary"
                  w="50%"
                  isLoading={uploading}
                  onClick={handleSafetyClick}
                >
                  Upload File +
                </Button>
              )}
            </HStack>

            <HStack justify="space-between">
              <Text pt={2}>Condition Report</Text>
              <Input
                type="file"
                id="partnerConditionReport"
                ref={conditionInput}
                style={{ display: 'none' }}
                onChange={onChangeFileInput}
                accept=".jpg,.png,.jpeg,.pdf"
              />

              {!listing.partnerConditionReport ? (
                <>
                  <Button
                    colorScheme="primary"
                    w="50%"
                    isLoading={uploading}
                    onClick={handleConditionClick}
                  >
                    Upload File +
                  </Button>
                </>
              ) : (
                <HStack w="50%">
                  <Button
                    variant="outline"
                    w="full"
                    colorScheme="primary"
                    rightIcon={<ExternalLinkIcon />}
                    onClick={() =>
                      window.open(listing.partnerConditionReport, '_blank')
                    }
                  >
                    Click to view
                  </Button>
                  <IconButton
                    isLoading={uploading}
                    onClick={() => {
                      if (
                        !window.confirm(
                          'Delete and upload again, are you sure?'
                        )
                      ) {
                        return;
                      }
                      handleConditionClick();
                    }}
                    colorScheme="primary"
                    size="sm"
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                  />
                </HStack>
              )}
            </HStack>

            <HStack justify="space-between">
              <Text pt={2}>Carfax Report</Text>
              <Input
                type="file"
                id="partnerCarfax"
                ref={carfaxInput}
                style={{ display: 'none' }}
                onChange={onChangeFileInput}
                accept=".jpg,.png,.jpeg,.pdf"
              />

              {!listing.partnerCarfax ? (
                <Button
                  colorScheme="primary"
                  w="50%"
                  isLoading={uploading}
                  onClick={handleCarfaxClick}
                >
                  Upload File +
                </Button>
              ) : (
                <HStack w="50%">
                  <Button
                    variant="outline"
                    w="full"
                    colorScheme="primary"
                    rightIcon={<ExternalLinkIcon />}
                    onClick={() => window.open(listing.partnerCarfax, '_blank')}
                  >
                    Click to view
                  </Button>
                  <IconButton
                    isLoading={uploading}
                    onClick={() => {
                      if (
                        !window.confirm(
                          'Delete and upload again, are you sure?'
                        )
                      ) {
                        return;
                      }
                      handleCarfaxClick();
                    }}
                    colorScheme="primary"
                    size="sm"
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                  />
                </HStack>
              )}
            </HStack>

            <HStack justify="space-between">
              <Text pt={2}>Repair Invoice</Text>
              <Input
                type="file"
                id="partnerInvoice"
                ref={invoiceInput}
                style={{ display: 'none' }}
                onChange={onChangeFileInput}
                accept=".jpg,.png,.jpeg,.pdf"
              />

              {!listing.partnerInvoice ? (
                <Button
                  colorScheme="primary"
                  w="50%"
                  isLoading={uploading}
                  onClick={handleInvoiceClick}
                >
                  Upload File +
                </Button>
              ) : (
                <HStack w="50%">
                  <Button
                    variant="outline"
                    w="full"
                    colorScheme="primary"
                    rightIcon={<ExternalLinkIcon />}
                    onClick={() =>
                      window.open(listing.partnerInvoice, '_blank')
                    }
                  >
                    Click to view
                  </Button>
                  <IconButton
                    isLoading={uploading}
                    onClick={() => {
                      if (
                        !window.confirm(
                          'Delete and upload again, are you sure?'
                        )
                      ) {
                        return;
                      }
                      handleInvoiceClick();
                    }}
                    colorScheme="primary"
                    size="sm"
                    aria-label="Delete"
                    icon={<DeleteIcon />}
                  />
                </HStack>
              )}
            </HStack>
          </Stack>
        </Stack>
        <br />
        <br />
        <br />
        <br />
        <br />
      </AppBody>
    </>
  );
}

export default Listing;
