import { Box, Container } from '@chakra-ui/react';

// header 60px
// footer 90px

export default function AppBody(props) {
  return (
    <Container
      p={2}
      minH={'calc(100vh - 120px)'}
    >

    <Box
      // minH={'calc(100vh - 120px)'}
      // maxW='4xl'
      // bg='#f6f6f6'
      >
      {props.children}
    </Box>
      </Container>
  );
}
