import { useContext, useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  FormControl,
  Heading,
  IconButton,
  Image,
  Input,
  InputLeftAddon,
  InputRightElement,
  InputGroup,
  Stack,
  Spinner,
  Text,
  HStack,
  useToast,
} from '@chakra-ui/react';
import InputMask from 'react-input-mask';
import { BsLock, BsPencil } from 'react-icons/bs';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import moment from 'moment';
import { updateDoc, doc, onSnapshot } from 'firebase/firestore';
import { getAuth, updateEmail } from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

import { db, isDev } from '../constants';
import DataContext from '../context/DataContext';
import AppBody from '../components/AppBody';
import Header from '../components/Header';
import SpinnerPage from '../components/SpinnerPage';

function Profile() {
  const dataContext = useContext(DataContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({});
  const [loadingPhotoURL, setLoadingPhotoURL] = useState(false);
  const [loadingCheque, setLoadingCheque] = useState(false);
  const toast = useToast();

  const fileInputRef = useRef(null);
  const fileInputRef2 = useRef(null);

  const {
    interacEmail,
    chequeImage,
    address,
    photoURL,
    businessName,
    businessLegalName,
    businessPhone,
    website,
    createdAt,
    refSlug,
    approved,
  } = formData;

  const { userData } = dataContext;
  const { partnerUid, admin, memberUid } = userData;

  useEffect(() => {
    if (!partnerUid) return navigate('/profile');
    const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
      setFormData(doc.data());
      setLoading(false);
    });
    return unsubscribe;
  }, [partnerUid, navigate]);

  const partnerRef = doc(db, 'partners', partnerUid);

  const onChange = e => {
    if (!admin) return;
    updateDoc(partnerRef, {
      [e.target.id]: e.target.value,
    });
  };
  const onChangeAddressComponent = e => {
    if (!admin) return;
    updateDoc(partnerRef, {
      address: {
        ...address,
        [e.target.id]: e.target.value,
      },
    });
  };

  const handleInputClick = () => {
    if (!admin) return;
    fileInputRef.current.click();
  };
  const handleInputClick2 = () => {
    if (!admin) return;
    fileInputRef2.current.click();
  };

  const onChangeFileInput = async e => {
    if (!admin) return;
    setLoadingPhotoURL(true);
    // console.log('e.target.files', e.target.files);
    // console.log('e.target.files[0]', e.target.files[0]);
    try {
      // Store image in firebase
      const storeImage = async image => {
        return new Promise((resolve, reject) => {
          const storage = getStorage();
          const fileName = `logo-${Date.now()}`;

          const storageRef = ref(
            storage,
            'images/partners/' + partnerUid + '/' + fileName
          );

          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            'state_changed',
            snapshot => {
              // const progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log('Upload is ' + progress + '% done');
              // switch (snapshot.state) {
              //   case 'paused':
              //     console.log('Upload is paused');
              //     break;
              //   case 'running':
              //     console.log('Upload is running');
              //     break;
              //   default:
              //     break;
              // }
            },
            error => {
              // console.log(error);
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                resolve(downloadURL);
              });
            }
          );
        });
      };

      const savedPhotoURL = await Promise.resolve(
        storeImage(e.target.files[0])
      ).catch(e => {
        // console.log('error', e);
        toast({
          title: 'Could not upload image',
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        return photoURL;
      });

      // console.log('savedPhotoURL', savedPhotoURL);

      // setFormData(prevState => ({
      //   ...prevState,
      //   photoURL: savedPhotoURL,
      // }));
      // console.log('formDataToSave before update', formDataToSave);
      // Update in firestore
      await updateDoc(partnerRef, {
        photoURL: savedPhotoURL,
      });
      setLoadingPhotoURL(false);
      toast({
        description: 'Logo updated',
        status: 'info',
      });
      // compareNamePostalAddWarnings();
    } catch (error) {
      console.log(error);
      setLoadingPhotoURL(false);
      toast({
        title: 'Could not update photo',
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };
  const onChangeFileInput2 = async e => {
    if (!admin) return;
    setLoadingCheque(true);
    // console.log('e.target.files', e.target.files);
    // console.log('e.target.files[0]', e.target.files[0]);
    try {
      // Store image in firebase
      const storeImage = async image => {
        return new Promise((resolve, reject) => {
          const storage = getStorage();
          const fileName = `cheque-${Date.now()}`;

          const storageRef = ref(
            storage,
            'images/partners/' + partnerUid + '/' + fileName
          );

          const uploadTask = uploadBytesResumable(storageRef, image);

          uploadTask.on(
            'state_changed',
            snapshot => {
              // const progress =
              //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              // console.log('Upload is ' + progress + '% done');
              // switch (snapshot.state) {
              //   case 'paused':
              //     console.log('Upload is paused');
              //     break;
              //   case 'running':
              //     console.log('Upload is running');
              //     break;
              //   default:
              //     break;
              // }
            },
            error => {
              // console.log(error);
              reject(error);
            },
            () => {
              // Handle successful uploads on complete
              getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                resolve(downloadURL);
              });
            }
          );
        });
      };

      const savedChequeURL = await Promise.resolve(
        storeImage(e.target.files[0])
      ).catch(e => {
        // console.log('error', e);
        toast({
          title: 'Could not upload image',
          status: 'error',
          isClosable: true,
        });
        // if fail, return what was there before
        return chequeImage;
      });

      // console.log('savedPhotoURL', savedPhotoURL);

      // setFormData(prevState => ({
      //   ...prevState,
      //   photoURL: savedPhotoURL,
      // }));
      // console.log('formDataToSave before update', formDataToSave);
      // Update in firestore
      await updateDoc(partnerRef, {
        chequeImage: savedChequeURL,
      });
      setLoadingCheque(false);
      toast({
        description: 'Cheque updated',
        status: 'info',
      });
      // compareNamePostalAddWarnings();
    } catch (error) {
      console.log(error);
      setLoadingCheque(false);
      toast({
        title: 'Could not update cheque',
        description: isDev ? JSON.stringify(error) : '',
        status: 'error',
        isClosable: true,
      });
    }
  };

  const onClickChangeEmail = async () => {
    const newEmail = await window.prompt('Enter new email address');
    // console.log(newEmail);

    if (!newEmail) return;
    if (!window.confirm(`Change email to ${newEmail}, are you sure?`)) {
      return;
    }
    const auth = getAuth();
    updateEmail(auth.currentUser, newEmail)
      .then(() => {
        // Email updated!
        // setFormData(prevState => ({
        //   ...prevState,
        //   email: newEmail,
        // }));
        const memberRef = doc(db, 'partnerMembers', memberUid);
        updateDoc(memberRef, {
          email: newEmail,
        });
        toast({
          title: 'Email updated',
          status: 'success',
        });
      })
      .catch(error => {
        console.log(error);
        toast({
          title:
            'Could not update email: please logout and login and try again',
          description: error?.message ? error.message : JSON.stringify(error),
          status: 'error',
          duration: 11000,
          isClosable: true,
        });
      });
  };

  const renderAddress = () => {
    return (
      <>
        <Text as="b">Business Address</Text>
        <Stack direction="row" w="100%" mt={1}>
          <Box flex="1" color={!address?.civic && 'red'} as="b">
            Civic
          </Box>
          <Box flex="1" as="b">
            Suite
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.civic || ''}
                id="civic"
                readOnly={approved}
                disabled={!admin}
                _focus={
                  approved && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {approved && (
                <InputRightElement children={<BsLock color="grey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="1">
            <Input
              type="text"
              disabled={!admin}
              value={address?.apt || ''}
              id="apt"
              onChange={onChangeAddressComponent}
            />
          </Box>
        </Stack>

        {/* <Stack direction="row" w="100%">
          <Box flex="1"></Box>
          <Box flex="2" as="b">
            Suite
          </Box>
        </Stack> */}

        {/* <Stack direction="row" w="100%">
          <Box flex="1"></Box>
          <Box flex="2">
            <Input
              type="text"
              value={address?.apt || ''}
              id="apt"
              onChange={onChangeAddressComponent}
            />
          </Box>
        </Stack> */}

        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.street && 'red'} as="b">
            Street
          </Box>
          <Box flex="1" color={!address?.city && 'red'} as="b">
            City
          </Box>
        </Stack>

        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.street || ''}
                readOnly={approved}
                disabled={!admin}
                id="street"
                _focus={
                  approved && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {approved && (
                <InputRightElement children={<BsLock color="grey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.city || ''}
                id="city"
                disabled={!admin}
                _focus={
                  approved && {
                    borderColor: 'inherit',
                  }
                }
                readOnly={approved}
                onChange={onChangeAddressComponent}
              />
              {approved && (
                <InputRightElement children={<BsLock color="grey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>

        <Stack direction="row" w="100%" mt={2}>
          <Box flex="1" color={!address?.province && 'red'} as="b">
            Province
          </Box>
          <Box flex="1" color={!address?.postal && 'red'} as="b">
            Postal
          </Box>
        </Stack>
        <Stack direction="row" w="100%">
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.province || ''}
                readOnly={approved}
                disabled={!admin}
                id="province"
                _focus={
                  approved && {
                    borderColor: 'inherit',
                  }
                }
                onChange={onChangeAddressComponent}
              />
              {approved && (
                <InputRightElement children={<BsLock color="grey" />} />
              )}
            </InputGroup>
          </Box>
          <Box flex="1">
            <InputGroup>
              <Input
                type="text"
                value={address?.postal || ''}
                readOnly={approved}
                disabled={!admin}
                _focus={
                  approved && {
                    borderColor: 'inherit',
                  }
                }
                id="postal"
                onChange={onChangeAddressComponent}
              />
              {approved && (
                <InputRightElement children={<BsLock color="grey" />} />
              )}
            </InputGroup>
          </Box>
        </Stack>
      </>
    );
  };

  const renderInformation = () => {
    return (
      <>
        <Stack spacing={2}>
          <Text as="b">Business Name</Text>
          <InputGroup>
            <Input
              id="businessName"
              type="text"
              value={businessName || ''}
              disabled={!admin}
              readOnly={approved}
              _focus={
                approved && {
                  borderColor: 'inherit',
                }
              }
              onChange={onChange}
            />
            {approved && (
              <InputRightElement children={<BsLock color="grey" />} />
            )}
          </InputGroup>
          <Text as="b">Legal Name</Text>
          <InputGroup>
            <Input
              id="businessLegalName"
              type="text"
              value={businessLegalName || ''}
              readOnly={approved}
              disabled={!admin}
              _focus={
                approved && {
                  borderColor: 'inherit',
                }
              }
              onChange={onChange}
            />
            {approved && (
              <InputRightElement children={<BsLock color="grey" />} />
            )}
          </InputGroup>

          <Text as="b">Main Phone</Text>
          <InputGroup>
            <InputLeftAddon children="+1" />
            <Input
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              onChange={onChange}
              disabled={!admin}
              type="tel"
              placeholder=""
              id="businessPhone"
              value={businessPhone || ''}
            />
          </InputGroup>
          <Text as="b">Website</Text>
          <Input
            id="website"
            type="text"
            value={website || ''}
            disabled={!admin}
            onChange={onChange}
          />
          <input
            type="file"
            id="photoURL"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={onChangeFileInput}
            accept="image/*"
            // capture="user"
            // required
          />
          <HStack>
            <Box w="50%">
              <Text as="b">Business Logo</Text>
              <Text color="primary.500">
                Displayed on referral link signup page
              </Text>
            </Box>
            <Box w="50%">
              <FormControl
              // w="94px"
              >
                {loadingPhotoURL ? (
                  <Flex
                    h="94"
                    w="full"
                    // bg="lightgrey"
                    justify="center"
                    align="center"
                  >
                    <Spinner size="xl" />
                  </Flex>
                ) : (
                  <>
                    <Image
                      src={photoURL || ''}
                      maxH="150px"
                      alt="Company Logo"
                      fallbackSrc="https://placehold.co/100x70?text=LOGO"
                    />
                    <Box position="absolute" right={0} bottom={0}>
                      <IconButton
                        bg="gray.700"
                        color="white"
                        // size="sm"
                        onClick={handleInputClick}
                        disabled={!admin}
                        variant="solid"
                        aria-label="Edit Logo"
                        icon={<BsPencil />}
                      />
                    </Box>
                  </>
                )}
              </FormControl>
            </Box>
          </HStack>
          

          <Divider borderColor="primary.500" borderWidth={1} />
          <HStack justify="space-between">
            <Text as="b">Affiliate Agreement</Text>
          </HStack>

          {!approved ? (
            <Alert status="info">
              <AlertIcon />
              You may view and sign the agreement once your profile is approved
            </Alert>
          ) : (
            <>
            
          <Text>
            Click here to view the terms of the EEZE Affiliate Program Agreement
            and execute the contract
          </Text>

          <Button disabled={!admin} colorScheme="primary" onClick={() => navigate('/contract')}>
            Go To Contract
          </Button>
          </>
          )}


          <Divider borderColor="primary.500" borderWidth={1}/>
            <Box as="b">Referral Link</Box>


            <Text color="primary.500">
             Unique signup link to share with customers. Click Invite in menu to share
            </Text>
          <InputGroup alignItems="center">
            <Text ml={4}>https://eeze.co/</Text>
            <Input
              id="refSlug"
              type="text"
              color="blue"
              // w='50%'
              flex={1}
              value={refSlug || ''}
              disabled={!admin}
              // disabled
              readOnly={approved}
              _focus={{
                borderColor: 'inherit',
              }}
              onChange={onChange}
            />
            {approved && (
              <InputRightElement children={<BsLock color="grey" />} />
            )}
          </InputGroup>
          {!approved && (
            <Alert status="info">
              <AlertIcon />
              Not active until profile approved
              {/* <br/>
              Alternative:<br/>
              https://app.eeze.co/signup/{refSlug} */}
            </Alert>
          )}

          {/* <Divider borderColor="primary.500" borderWidth={1} />

          <Box w="30%" as="b">
            Contact
          </Box>

          <HStack>
            <Box w="50%">
              <Input
                type="text"
                id="primaryFirstName"
                placeholder="First Name"
                value={primaryFirstName}
                onChange={onChange}
              />
            </Box>
            <Box w="50%">
              <Input
                type="text"
                id="primaryLastName"
                placeholder="Last Name"
                value={primaryLastName}
                onChange={onChange}
              />
            </Box>
          </HStack>

          <Box w="30%" as="b">
            Title
          </Box>
          <HStack>
            <Box w="100%">
              <Input
                id="primaryTitle"
                type="text"
                value={primaryTitle}
                onChange={onChange}
              />
            </Box>
          </HStack>
          <Box w="30%" as="b">
            Login Email
          </Box>
          <HStack>
            <Box w="100%">
              <InputGroup>
                <Input
                  type="email"
                  value={primaryEmail}
                  color="grey"
                  readOnly
                  _focus={{
                    borderColor: 'inherit',
                  }}
                />
                <InputRightElement children={<BsLock color="lightgrey" />} />
              </InputGroup>
            </Box>
          </HStack>

          <Box w="30%" as="b">
            Cell Phone
          </Box>
          <Box w="100%">
            <InputGroup>
              <InputLeftAddon children="+1" />
              <Input
                as={InputMask}
                mask="(999) 999-9999"
                maskChar={null}
                type="tel"
                id="primaryCell"
                value={primaryCell}
                onChange={onChange}
              />
            </InputGroup>
          </Box> */}
        </Stack>
      </>
    );
  };

  const renderPayment = () => {
    return (
      <>
        <Box as="b">Payment Method</Box>

        <Text color="primary.500">
          EEZE will distribute affiliate income to partners according to the
          terms of the agreement
        </Text>
        {/* <Text>Please indicate your desired payment method</Text> */}

        <Box as="b">Interac e-Transfer recipient email address</Box>
        <Input
          type="text"
          value={interacEmail || ''}
          placeholder="Interac email"
          id="interacEmail"
          _focus={{
            borderColor: 'inherit',
          }}
          onChange={onChange}
          disabled={!admin}
        />
        {/* <Text color="primary.500">
          Make sure you are able to deposit funds into your online banking from
          this email address
        </Text> */}

        <input
          type="file"
          id="chequeImage"
          ref={fileInputRef2}
          style={{ display: 'none' }}
          onChange={onChangeFileInput2}
          accept=".jpg,.png,.jpeg,.pdf"
        />

        <Box mt={2}>
          <Text as="b">Voided Company Cheque</Text>
        </Box>
        <HStack>
          <Box w="50%">
            <Text color="primary.500">
              EEZE can send a direct deposit to this account
            </Text>
          </Box>
          <Box w="50%">
            <FormControl
            // w="94px"
            >
              {loadingCheque ? (
                <Flex
                  h="94"
                  w="full"
                  // bg="lightgrey"
                  justify="center"
                  align="center"
                >
                  <Spinner size="xl" />
                </Flex>
              ) : (
                <>
                  <Image
                    src={chequeImage}
                    alt="Company Cheque"
                    maxH="150px"
                    fallback={
                      chequeImage && (
                        <Button
                          variant="outline"
                          colorScheme="primary"
                          // py={16}
                          w="full"
                          disabled={!admin}
                          onClick={() => window.open(chequeImage, '_blank')}
                          rightIcon={<ExternalLinkIcon />}
                        >
                          VIEW
                        </Button>
                      )
                    }
                    fallbackSrc="https://placehold.co/100x70?text=CHQ"
                  />

                  <Box position="absolute" right={0} bottom={0}>
                    <IconButton
                      bg="gray.700"
                      color="white"
                      // size="sm"
                      onClick={handleInputClick2}
                      disabled={!admin}
                      variant="solid"
                      aria-label="Edit Cheque"
                      icon={<BsPencil />}
                    />
                  </Box>
                </>
              )}
            </FormControl>
          </Box>
        </HStack>
      </>
    );
  };

  const onChangeMember = (e, memberUid) => {
    const partnerRef = doc(db, 'partnerMembers', memberUid);
    updateDoc(partnerRef, {
      [e.target.id]: e.target.value,
    });
  };

  const renderMemberInfo = () => {
    return (
      <Card borderColor="primary.500" borderWidth={1} p={2} my={4}>
        <Box my={2}>
          <Text fontSize="xl" as="b">
            Your User Information
          </Text>
        </Box>
        <HStack>
          <Box w="50%">
            <Input
              type="text"
              id="firstName"
              placeholder="First Name"
              value={userData.firstName}
              onChange={e => onChangeMember(e, userData.memberUid)}
            />
          </Box>
          <Box w="50%">
            <Input
              type="text"
              id="lastName"
              placeholder="Last Name"
              value={userData.lastName}
              onChange={e => onChangeMember(e, userData.memberUid)}
            />
          </Box>
        </HStack>

        <Box w="30%" as="b">
          Title
        </Box>
        <HStack>
          <Box w="100%">
            <Input
              id="title"
              type="text"
              value={userData.title}
              onChange={e => onChangeMember(e, userData.memberUid)}
              // _focus={{
              //   borderColor: 'inherit',
              // }}
              // onChange={onChange}
            />
          </Box>
        </HStack>
        <Box w="30%" as="b">
          Login Email
        </Box>
        <HStack>
          <Box w="100%">
            <InputGroup>
              <Input
                type="email"
                value={userData.email}
                readOnly
                bgColor="white"
                // color="grey"
                // readOnly
                _focus={{
                  borderColor: 'inherit',
                }}
              />
              <InputRightElement children={<BsLock color="grey" />} />
            </InputGroup>
          </Box>
        </HStack>
        {/* <Center mb={10}> */}
        <Button
          variant="link"
          colorScheme="primary"
          onClick={onClickChangeEmail}
          size="sm"
          w="full"
          as="u"
        >
          Change login email
        </Button>
        {/* </Center> */}
        <Text align="center" color="grey">
          To change password, Log Out
          <br /> and select Forgot Password
        </Text>
        <Box as="b">Business Phone</Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftAddon children="+1" />
            <Input
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              type="tel"
              id="phone"
              value={userData.phone}
              onChange={e => onChangeMember(e, userData.memberUid)}
            />
          </InputGroup>
        </Box>
        <Box w="30%" as="b">
          Cell Phone
        </Box>
        <Box w="100%">
          <InputGroup>
            <InputLeftAddon children="+1" />
            <Input
              as={InputMask}
              mask="(999) 999-9999"
              maskChar={null}
              type="tel"
              id="cell"
              value={userData.cell}
              onChange={e => onChangeMember(e, userData.memberUid)}
              // readOnly
              // _focus={{
              //   borderColor: 'inherit',
              // }}
              // onChange={onChange}
            />
          </InputGroup>
        </Box>
      </Card>
    );
  };
  if (loading) {
    return <SpinnerPage />;
  }
  return (
    <>
      <Header back />
      <AppBody>
        {/* <Tabs isFitted>
          <TabList>
            <Tab align='left' p={0}>
              <Heading size="md" align='left' p={0}>Profile {!admin && ' (read-only)'}</Heading>
            </Tab>
            <Tab px={35}>
              <Heading size="md">Users {!admin && ' (read-only)'}</Heading>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} m={0}>
              show profile
            </TabPanel>
            <TabPanel>show users</TabPanel>
          </TabPanels>
        </Tabs> */}
        <HStack justify="space-between">
          <Heading size="md">Profile</Heading>
          {admin ? (
            <Button size="sm" onClick={() => navigate('/users')}>
              Add/View Users
            </Button>
          ) : (
            <Text as="i">(business information is read-only)</Text>
          )}
        </HStack>

        {!approved && (
          <Alert status="info" mt={1}>
            <AlertIcon />
            Business Profile is pending approval by EEZE
          </Alert>
        )}
        <Divider borderColor="primary.500" borderWidth={1} my={2} />

        {renderInformation()}

        <Divider borderColor="primary.500" borderWidth={1} my={2} />

        {renderPayment()}

        <Divider borderColor="primary.500" borderWidth={1} my={2} />

        {renderAddress()}

        {/* <Divider borderColor="primary.500" borderWidth={1} my={2} /> */}

        {renderMemberInfo()}

        {/* <Divider borderColor="primary.500" borderWidth={1} my={2} /> */}
        <br />
        <Text as="i" color="grey">
          Some fields are locked once approved,{' '}
          <a
            href={'https://eeze.co/contact-us/'}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            contact us
          </a>{' '}
          to make major changes to your profile
        </Text>
        <Box my={50}>
          <Text>
            With EEZE since:{' '}
            {moment(createdAt, 'ddd MMM DD YYYY kk:mm:ss Z ZZ').format(
              'MMM D, YYYY'
            )}
          </Text>
        </Box>
      </AppBody>
    </>
  );
}

export default Profile;
