import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'


export const isDev = true;

export const masterSafetyPromotionEnabled = true; // if option exists when creating a vehicle
export const deployed = isDev ? '240829 0600' : '';
export const allowManualVehicle = true;

let firebaseConfig;
let baseServerUrl;

if (isDev) {
  // dev credentials
  baseServerUrl = process.env.REACT_APP_BASE_SERVER_DEV;
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY_DEV,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN_DEV,
    projectId: process.env.REACT_APP_PROJECT_ID_DEV,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET_DEV,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID_DEV,
  };

  if (process.env.REACT_APP_APP_ID_DEV) {
    firebaseConfig.appId = process.env.REACT_APP_APP_ID_DEV;
  }

  if (process.env.REACT_APP_MEASUREMENT_ID_DEV) {
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENT_ID_DEV;
  }
} else {
  // prod credentials
  baseServerUrl = process.env.REACT_APP_BASE_SERVER;
  firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  };

  if (process.env.REACT_APP_APP_ID) {
    firebaseConfig.appId = process.env.REACT_APP_APP_ID;
  }

  if (process.env.REACT_APP_MEASUREMENT_ID) {
    firebaseConfig.measurementId = process.env.REACT_APP_MEASUREMENT_ID;
  }
}

export const eligibleWarrantyProvinces = ['on', 'qc'];

export const recaptchaKey = '6LfIQn8pAAAAALZyye5yruoLXD8ZD2nQzc5hUPsa';


export const EEZE_LOGIN_URL = isDev ? 'https://dev-app.eeze.co/login' : 'https://app.eeze.co/login';

export const CHECK_CELL = baseServerUrl + '/pub/checkCell';

export const SEND_EMAIL = baseServerUrl + '/sendEmail?token=ezeze&';

export const SEND_EMAIL_TO_SELLER = baseServerUrl + '/app/sendEmailToSeller';

export const SEND_NOTICE_TO_OWNER = baseServerUrl + '/app/sendWarrantyNoticeToOwner';

export const SEND_SAFETY_PROMO_NOTICE_TO_OWNER = baseServerUrl + '/app/sendSafetyUploadNoticeToOwner';

export const SEND_EMAIL_TO_ADMIN = baseServerUrl + '/app/sendEmailToAdmin';

export const STAGE_TWO_VERIFY = baseServerUrl + '/app/stageTwoVerifyUser';

export const SEND_EMAIL_LINK = baseServerUrl + '/app/sendEmailLink';

export const SEND_SMS = baseServerUrl + '/sendSMSMessage?token=ezeze&';

export const SEND_SMS_SIGNUP_LINK = baseServerUrl + '/pub/sendSMSSignupLink?token=ezeze&';

export const SEND_SMS_LINK = baseServerUrl + '/app/sendSMSLink';

export const CARFAX_REQUIRED = baseServerUrl + '/app/carfaxRequired';

export const INSPECTION_REQUIRED = baseServerUrl + '/app/inspectionRequired';

export const CREATE_MEMBER = baseServerUrl + '/app/createMember';

export const CAPTURE_VEHICLE = baseServerUrl + '/app/createSession';

export const TWILIO_START_VERIFY = 'https://twilio-verify-2977-dev.twil.io/start-verify';

export const TWILIO_VERIFY_OTP = 'https://twilio-verify-2977-dev.twil.io/verify-otp';

export const GENERATE_LICENSE_VERIFY = baseServerUrl + '/app/generateInquiry';

export const DELETE_SHORT_URL = baseServerUrl + '/app/deleteShortUrl';

export const FUND_WALLET = baseServerUrl + '/app/fundWallet';

export const PURCHASE_ADDON = baseServerUrl + '/app/purchaseAddon';

export const BANKING_IFRAME = isDev ?
  // 'https://connector-sandbox.aggregation.zumrails.com/?customerid=25fec733-27e0-46ef-8f6e-51b3adab0ed9&testinstitution=true&buttonbgcolor=%25236d2367&logourl=https%253A%252F%252Feeze-website.web.app%252Fimg%252Fshield1024.png&adduserinportal=true'
  'https://connector-sandbox.aggregation.zumrails.com/?customerid=25fec733-27e0-46ef-8f6e-51b3adab0ed9&testinstitution=true&buttonbgcolor=%25236d2367&logourl=https%253A%252F%252Feeze-website.web.app%252Fimg%252Fshield1024.png&tecurl=https%253A%252F%252Feeze.co%252Fterms-of-use%252F&gettransactions=false'
  : 'https://connector.aggregation.zumrails.com/?customerid=8621f191-a0a4-47bf-ab25-2ffb5a7342a7&testinstitution=false&buttonbgcolor=%25236d2367&logourl=https%253A%252F%252Feeze-website.web.app%252Fimg%252Fshield1024.png&adduserinportal=true';

export const GET_BANKING_IFRAME = baseServerUrl + '/app/getIframeUrl';

export const SEND_EMAIL_PASSCODE = baseServerUrl + '/app/sendEmailPasscode';

export const DECODE_VIN = baseServerUrl + '/app/decodeVIN';

export const GENERATE_CONTRACT = baseServerUrl + '/app/generateFinalContract';

export const GENERATE_AFFILIATE_AGREEMENT = baseServerUrl + '/app/generateAffiliateAgreement';

export const VERIFY_CAPTCHA = baseServerUrl + '/verifyCaptcha';

// export const EEZE_LOGIN_URL = isDev ? 'https://dev-app.eeze.co/login' : 'https://app.eeze.co/login';

// export const SEND_EMAIL = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/sendEmail?token=ezeze&':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/sendEmail?token=ezeze&';

// export const SEND_EMAIL_TO_SELLER = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailToSeller':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailToSeller';

// export const SEND_EMAIL_TO_ADMIN = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailToAdmin':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailToAdmin';

// export const STAGE_TWO_VERIFY = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/stageTwoVerifyUser':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/stageTwoVerifyUser';

// export const SEND_EMAIL_LINK = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailLink':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailLink';

// export const SEND_SMS = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/sendSMSMessage?token=ezeze&':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/sendSMSMessage?token=ezeze&';

// export const SEND_SMS_LINK = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendSMSLink':
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendSMSLink';

// export const TWILIO_START_VERIFY = isDev ?
//   'https://twilio-verify-2977-dev.twil.io/start-verify' :
//   'https://twilio-verify-2977-dev.twil.io/start-verify';

// export const TWILIO_VERIFY_OTP = isDev ?
//   'https://twilio-verify-2977-dev.twil.io/verify-otp' :
//   'https://twilio-verify-2977-dev.twil.io/verify-otp';

// export const GENERATE_LICENSE_VERIFY = isDev ?
// 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/generateDocupass' :
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/generateDocupass';

// export const DELETE_SHORT_URL = isDev ?
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/deleteShortUrl' :
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/deleteShortUrl';

// export const CREATE_MEMBER = isDev ?
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/createMember':
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/createMember';

// export const GET_BANKING_IFRAME = isDev ?
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/getIframeUrl':
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/getIframeUrl';
//   // these are vopay
//   // 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/getBankingUrl':
//   // 'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/getBankingUrl';

// export const SEND_EMAIL_PASSCODE = isDev ?
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailPasscode' :
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/sendEmailPasscode';

// export const DECODE_VIN = isDev ?
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/decodeVIN' :
//   'https://us-central1-eeze-dev-storage-db-auth.cloudfunctions.net/app/decodeVIN';

// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()

export const LISTING = {
  STATUS: {
    DRAFT: 'DRAFT',
    POSTED: 'POSTED',
    ACCEPTED: 'ACCEPTED',  // confirm terms and select options
    SELECT_OPTIONS: 'SELECT_OPTIONS',
    DISPLAY_OPTIONS: 'DISPLAY_OPTIONS',
    BUYER_FUNDING: 'BUYER_FUNDING',
    BUYER_SIGNING: 'BUYER_SIGNING', // buyer is signing
    BUYER_SIGNED: 'BUYER_SIGNED',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
  },
  TYPE: {
    VEHICLE: 'VEHICLE',
    PRODUCT: 'PRODUCT'
  }
}

export const LINK = isDev ? {
  privacyUrl: 'https://eeze.co/privacy-policy/',
  termsUrl: 'https://eeze.co/terms-of-use/',
  amlUrl: 'https://eeze.co/aml-program/',
  googleReview: 'https://g.page/r/CY3GFDptbIQMEBI/review',
  contactUrl: 'https://eeze.co/contact-us/',
  buyCarOntarioUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  buyCarQuebecUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  warrantyPlansUrl: 'https://eeze.co/get-a-warranty/',
  sampleCarfaxUrl: 'https://vhr.carfax.ca/en-ca/sample/vhrlc',

  walletFundCredit: 'https://buy.stripe.com/test_dR6eWM7eQ9Rrds4dQQ', // no taxes collected

  carfaxVhrCreditON: 'https://buy.stripe.com/test_6oEaGw8iU0gRgEgfYZ', // stripe collects tax
  carfaxVhrCreditQC: 'https://buy.stripe.com/test_6oEaGw8iU0gRgEgfYZ', // stripe collects tax
  warrantyPowertrain1yrCreditON: 'https://buy.stripe.com/test_28oeWM8iUd3Dew8aEH', // stripe collects tax
  warrantyPowertrain1yrCreditQC: 'https://buy.stripe.com/test_28oeWM8iUd3Dew8aEH', // stripe collects tax
  warrantyComprehensive1yrCreditON: 'https://buy.stripe.com/test_aEUaGw56Id3DafS6ov',
  warrantyComprehensive1yrCreditQC: 'https://buy.stripe.com/test_cN215Wcza8Nnds4aEK',
  warrantyComprehensive2yrCreditON: 'https://buy.stripe.com/test_14k8yodDe1kVfAc9AE',
  warrantyComprehensive2yrCreditQC: 'https://buy.stripe.com/test_bIY9Cs2YAgfPew85kp',
  warrantyComprehensive3yrCreditON: 'https://buy.stripe.com/test_aEUeWMeHi7JjafSeV2',
  warrantyComprehensive3yrCreditQC: 'https://buy.stripe.com/test_7sI7ukar2e7HbjW5kt',

  warrantyPowertrain1yrCreditUpgradeON: 'https://buy.stripe.com/test_8wMbKAdDe9Rr9bO146', // stripe collects tax
  warrantyPowertrain1yrCreditUpgradeQC: 'https://buy.stripe.com/test_8wMbKAdDe9Rr9bO146', // stripe collects tax
  warrantyComprehensive1yrCreditUpgradeON: 'https://buy.stripe.com/test_6oE2a042E0gRew8dR0',
  warrantyComprehensive1yrCreditUpgradeQC: 'https://buy.stripe.com/test_3cs6qg7eQ7Jj73G5kv',
  warrantyComprehensive2yrCreditUpgradeON: 'https://buy.stripe.com/test_14k9Cs8iU2oZ4VydR2',
  warrantyComprehensive2yrCreditUpgradeQC: 'https://buy.stripe.com/test_3csaGweHiaVvfAceV7',
  warrantyComprehensive3yrCreditUpgradeON: 'https://buy.stripe.com/test_cN26qg56I4x79bObIW',
  warrantyComprehensive3yrCreditUpgradeQC: 'https://buy.stripe.com/test_8wMg0Q6aMaVvds47sH',
} : {
  privacyUrl: 'https://eeze.co/privacy-policy/',
  termsUrl: 'https://eeze.co/terms-of-use/',
  amlUrl: 'https://eeze.co/aml-program/',
  googleReview: 'https://g.page/r/CY3GFDptbIQMEBI/review',
  contactUrl: 'https://eeze.co/contact-us/',
  buyCarOntarioUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  buyCarQuebecUrl: 'https://eeze.co/how-to-sell-your-car-in-ontario-with-eeze/',
  warrantyPlansUrl: 'https://eeze.co/get-a-warranty/',
  sampleCarfaxUrl: 'https://vhr.carfax.ca/en-ca/sample/vhrlc',
  // need to copy test to production
  walletFundCredit: 'https://buy.stripe.com/cN2eYK2Qu3oU4rSbIX',

  carfaxVhrCreditON: 'https://buy.stripe.com/8wMg2Oezc8Je9Mc9AO',
  carfaxVhrCreditQC: 'https://buy.stripe.com/8wMg2Oezc8Je9Mc9AO',
  warrantyPowertrain1yrCreditON: 'https://buy.stripe.com/6oE9Eq4YC9NiaQgfZa',
  warrantyPowertrain1yrCreditQC: 'https://buy.stripe.com/6oE9Eq4YC9NiaQgfZa',
  warrantyComprehensive1yrCreditON: 'https://buy.stripe.com/dR69Eqcr4aRm7E4fZ9',
  warrantyComprehensive1yrCreditQC: 'https://buy.stripe.com/5kAaIuezc7FabUk14e',
  warrantyComprehensive2yrCreditON: 'https://buy.stripe.com/5kAcQCdv8e3ycYoaEN',
  warrantyComprehensive2yrCreditQC: 'https://buy.stripe.com/aEU03Q8aO7Faf6w7sA',
  warrantyComprehensive3yrCreditON: 'https://buy.stripe.com/7sI9Eq1MqaRm0bC7sz',
  warrantyComprehensive3yrCreditQC: 'https://buy.stripe.com/6oEdUG62G5x2f6wcMS',

  warrantyPowertrain1yrCreditUpgradeON: 'https://buy.stripe.com/aEU2bY4YC0cI9Mc5kx',
  warrantyPowertrain1yrCreditUpgradeQC: 'https://buy.stripe.com/aEU2bY4YC0cI9Mc5kx',
  warrantyComprehensive1yrCreditUpgradeON: 'https://buy.stripe.com/bIY3g20ImaRmgaA005',
  warrantyComprehensive1yrCreditUpgradeQC: 'https://buy.stripe.com/6oE6se76Ke3y1fG004',
  warrantyComprehensive2yrCreditUpgradeON: 'https://buy.stripe.com/cN2eYKaiWaRmgaA7sv',
  warrantyComprehensive2yrCreditUpgradeQC: 'https://buy.stripe.com/4gwbMybn0aRmf6w4gi',
  warrantyComprehensive3yrCreditUpgradeON: 'https://buy.stripe.com/5kA5oacr43oUf6waEF',
  warrantyComprehensive3yrCreditUpgradeQC: 'https://buy.stripe.com/dR6aIucr43oUaQg288',
};

export const PRICING_OPTIONS = {
  transaction: {
    nsf: 20,
    fundMinimum: 2,
    fundMaximum: 25000,
  },
  addons: {
    "carfax": {
      name: "Vehicle History Report + Lien Check",
      description: "",
      code: "carfax",
      type: "CARFAX",
      on: {
        checkoutUrl: LINK.carfaxVhrCreditON,
        priceIncludingTax: 73.35,
        priceBeforeTax: 65,
        taxes: {
          gst: 8.45,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.carfaxVhrCreditQC,
        priceIncludingTax: 74.73,
        priceBeforeTax: 65,
        taxes: {
          gst: 3.25,
          qst: 6.48,
        },
      },
    },
    "BASE90": {
      name: "Powertrain 90-Days",
      term: "90 Days",
      description: "Included in EEZE Plan",
      code: "BASE90",
      type: "BASE",
      on: {
        checkoutUrl: '',
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: '',
        priceIncludingTax: 0,
        priceBeforeTax: 0,
        taxes: {
          gst: 0,
          qst: 0,
        },
      },
    },
    "A1": {
      name: "Powertrain",
      term: "1 Year",
      warrantyName: "Powertrain 1-Year",
      feature1: "$0 Deductible",
      feature2: "$1,500 Maximum per claim",
      feature3: "Max claims - vehicle value",
      price1: "$570 + tax",
      price2: "",
      code: "A1",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditON,
        priceIncludingTax: 644.1,
        priceBeforeTax: 570,
        taxes: {
          gst: 74.1,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditQC,
        priceIncludingTax: 655.36,
        priceBeforeTax: 570,
        taxes: {
          gst: 28.5,
          qst: 56.86,
        },
      },
    },
    "B1": {
      name: "Comprehensive",
      feature1: "$0 Deductible",
      warrantyName: "Comprehensive 1-Year",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$1,075 + tax",
      price2: "",
      code: "B1",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditON,
        priceIncludingTax: 1214.75,
        priceBeforeTax: 1075,
        taxes: {
          gst: 139.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditQC,
        priceIncludingTax: 1235.98,
        priceBeforeTax: 1075,
        taxes: {
          gst: 53.75,
          qst: 107.23,
        },
      },
    },
    "B2": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 2-Year",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "2 Year",
      price1: "$1,775 + tax",
      price2: "($888/year)",
      code: "B2",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditON,
        priceIncludingTax: 2005.75,
        priceBeforeTax: 1775,
        taxes: {
          gst: 230.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditQC,
        priceIncludingTax: 2040.81,
        priceBeforeTax: 1775,
        taxes: {
          gst: 88.75,
          qst: 177.06,
        },
      },
    },
    "B3": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 3-Year",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "3 Year",
      price1: "$2,075 + tax",
      price2: "($692/year)",
      code: "B3",
      type: "WARRANTY",
      on: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditON,
        priceIncludingTax: 2344.75,
        priceBeforeTax: 2075,
        taxes: {
          gst: 269.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditQC,
        priceIncludingTax: 2385.73,
        priceBeforeTax: 2075,
        taxes: {
          gst: 103.75,
          qst: 206.98,
        },
      },
    },
    "A1U": {
      name: "Powertrain",
      feature1: "$0 Deductible",
      warrantyName: "Powertrain 1-Year Upgrade",
      feature2: "$1,500 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$475 + tax",
      price2: "",
      code: "A1U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditUpgradeON,
        priceIncludingTax: 536.75,
        priceBeforeTax: 475,
        taxes: {
          gst: 61.75,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyPowertrain1yrCreditUpgradeQC,
        priceIncludingTax: 546.13,
        priceBeforeTax: 475,
        taxes: {
          gst: 23.75,
          qst: 47.38,
        },
      },
    },
    "B1U": {
      name: "Comprehensive",
      feature1: "$0 Deductible",
      warrantyName: "Comprehensive 1-Year Upgrade",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "1 Year",
      price1: "$980 + tax",
      price2: "",
      code: "B1U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditUpgradeON,
        priceIncludingTax: 1107.4,
        priceBeforeTax: 980,
        taxes: {
          gst: 127.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive1yrCreditUpgradeQC,
        priceIncludingTax: 1126.76,
        priceBeforeTax: 980,
        taxes: {
          gst: 49,
          qst: 97.76,
        },
      },
    },
    "B2U": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 2-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "2 Year",
      price1: "$1,680 + tax",
      price2: "($840/year)",
      code: "B2U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditUpgradeON,
        priceIncludingTax: 1898.4,
        priceBeforeTax: 1680,
        taxes: {
          gst: 218.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive2yrCreditUpgradeQC,
        priceIncludingTax: 1931.58,
        priceBeforeTax: 1680,
        taxes: {
          gst: 84,
          qst: 167.48,
        },
      },
    },
    "B3U": {
      name: "Comprehensive",
      warrantyName: "Comprehensive 3-Year Upgrade",
      feature1: "$0 Deductible",
      feature2: "$5,000 Maximum per claim",
      feature3: "Max claims - vehicle value",
      term: "3 Year",
      price1: "$1,980 + tax",
      price2: "($660/year)",
      code: "B3U",
      type: "WARRANTY",
      upgrade: true,
      on: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditUpgradeON,
        priceIncludingTax: 2237.4,
        priceBeforeTax: 1980,
        taxes: {
          gst: 257.4,
          qst: 0,
        },
      },
      qc: {
        checkoutUrl: LINK.warrantyComprehensive3yrCreditUpgradeQC,
        priceIncludingTax: 2276.51,
        priceBeforeTax: 1980,
        taxes: {
          gst: 99,
          qst: 197.51,
        },
      },
    },
  },
};

