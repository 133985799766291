import React from 'react';
import { useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, signOut, signInWithEmailAndPassword } from 'firebase/auth';
import { updateDoc, doc, getDoc } from 'firebase/firestore';
import { db, deployed } from '../constants';
// Chakra imports
import {
  Box,
  Flex,
  Button,
  Divider,
  IconButton,
  Image,
  FormControl,
  Heading,
  InputGroup,
  InputRightElement,
  Center,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
// import ReCAPTCHA from 'react-google-recaptcha';

// import OAuth from '../components/OAuth';
import logoImage from '../assets/shield256.png';

// Assets
import signInImage from '../assets/signInImage.png';

function Login() {
  const navigate = useNavigate();
  const [queryParameters] = useSearchParams();
  const toast = useToast();
  const [showPassword, setShowPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [captchaIsDone, setcaptchaIsDone] = useState(false);
  const [formData, setFormData] = useState({
    email: queryParameters.get('email') || '',
    password: '',
  });
  const { email, password } = formData;

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  // const onChangeCaptcha = value => {
  //   // console.log('Captcha value:', value);
  //   setcaptchaIsDone(true);
  // };

  const onChange = e => {
    setFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const onSubmit = async e => {
    e.preventDefault();

    try {
      const auth = getAuth();

      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      ).catch(e => {
        if (e.code === 'auth/wrong-password') {
          toast({
            title: 'Please check the password',
            status: 'error',
            isClosable: true,
          });
        }
        if (e.code === 'auth/user-not-found') {
          toast({
            title: 'Please check the email',
            status: 'error',
            isClosable: true,
          });
        }
        return;
      });

      if (userCredential.user) {
        // login credentials are good
        // if no garage entry, logout and display error
        const docRef = doc(db, 'partnerMembers', userCredential.user.uid);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          // console.log('there is no member with this login, log out');
          return signOut(auth).then(() => {
            toast({
              title: 'User credentials error',
              description: 'Wrong portal',
              status: 'warning',
              isClosable: true,
            });
            navigate('/login');
          });
        }

        // console.log('usercredential user', userCredential.user)
        updateDoc(doc(db, 'partnerMembers', userCredential.user.uid), {
          lastLogin: new Date().toString(),
          // email: userCredential.user.email,
          // emailIsVerified: userCredential.user.emailVerified,
          // emailIsVerifiedAt: new Date().toString()
        });

        toast({
          title: 'Login Success',
          description: 'Welcome to EEZE!',
          // status: 'success',
          duration: 9000,
          isClosable: true,
        });
        // if there's a uid in the params, add it to redirect
        if (queryParameters.get('uid')) {
          const uid = queryParameters.get('uid');
          navigate('/?uid=' + uid);
        } else {
          navigate('/');
        }
      }
    } catch (error) {
      setIsSubmitting(false);
      toast({
        title: 'User credentials error',
        status: 'error',
        isClosable: true,
      });
    }
  };

  return (
    <Flex position="relative" mb="40px">
      <Flex
        h={{ sm: 'initial', md: '75vh', lg: '85vh' }}
        w="100%"
        maxW="1044px"
        mx="auto"
        justifyContent="space-between"
        mb="30px"
        pt={{ sm: '50px', md: '0px' }}
      >
        <Flex
          alignItems="center"
          justifyContent="start"
          style={{ userSelect: 'none' }}
          w={{ base: '100%', md: '50%', lg: '42%' }}
        >
          <Flex
            direction="column"
            w="100%"
            background="transparent"
            p="28px"
            mt={{ md: '150px', lg: '80px' }}
          >
            <Center>
              <Image
                src={logoImage}
                alt="Logo"
                boxSize="60px"
                objectFit="cover"
              />
            </Center>
            <Center>
              <Heading mb="10px" textAlign="center">
                Login to EEZE Partner Portal
              </Heading>
            </Center>
            <Divider borderColor="primary.800" my={5} />
            {/* <OAuth /> */}

            {/* <Flex
              align={'center'}
              _before={{
                content: '""',
                borderBottom: '1px solid',
                borderColor: 'secondary.100',
                flexGrow: 1,
                mr: 8,
              }}
              _after={{
                content: '""',
                borderBottom: '1px solid',
                borderColor: 'secondary.100',
                flexGrow: 1,
                ml: 8,
              }}
            >
              <Text fontSize="14px">OR</Text>
            </Flex> */}

            <Center>
              <Text mb="16px" mt="10px">
                Enter your email and password
              </Text>
            </Center>
            <form onSubmit={onSubmit}>
              <FormControl>
                <Input
                  borderRadius="10px"
                  mb="14px"
                  fontSize="sm"
                  type="email"
                  placeholder="Email address"
                  size="lg"
                  id="email"
                  value={email}
                  onChange={onChange}
                />
                <InputGroup>
                  <Input
                    borderRadius="10px"
                    mb="14px"
                    fontSize="sm"
                    type={showPassword ? 'text' : 'password'}
                    placeholder="Password"
                    size="lg"
                    id="password"
                    value={password}
                    onChange={onChange}
                  />
                  <InputRightElement h={'full'}>
                    {/* <Button
                    variant={'ghost'}
                    mb="14px"
                    onClick={togglePasswordVisibility}>
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button> */}

                    <IconButton
                      variant="ghost"
                      size={'lg'}
                      mb="14px"
                      icon={
                        showPassword ? (
                          <ViewOffIcon color={'secondary.300'} boxSize={6} />
                        ) : (
                          <ViewIcon color={'secondary.300'} boxSize={6} />
                        )
                      }
                      aria-label={'Toggle'}
                      onClick={togglePasswordVisibility}
                    />
                  </InputRightElement>
                </InputGroup>

                {/* <Center>
                  <ReCAPTCHA
                    sitekey={recaptchaKey}
                    onChange={onChangeCaptcha}
                  />
                </Center> */}
                {email && password ? (
                  <Button
                    type="submit"
                    colorScheme="primary"
                    w="100%"
                    // h="45"
                    mb="20px"
                    mt="14px"
                    isLoading={isSubmitting ? true : false}
                    disabled={isSubmitting ? true : false}
                  >
                    Login
                  </Button>
                ) : (
                  <Button
                    // type="submit"
                    colorScheme="primary"
                    w="100%"
                    // h="45"
                    mb="20px"
                    mt="14px"
                    disabled
                  >
                    Login
                  </Button>
                )}
              </FormControl>
            </form>
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              maxW="100%"
              mt="0px"
            >
              <Text fontWeight="medium">
                {`Don't have an account? `}
                <Link as="span" ms="5px" to="/signup">
                  Sign Up
                </Link>
              </Text>
            </Flex>
            <Center>
              <Link to="/forgot-password">
                <Text mt="30px" mb="30px" fontSize="14px">
                  Forgot password?
                </Text>
              </Link>
            </Center>
            <Center>

            <Text fontSize="sm" color="grey" as="i">
                {deployed}
              </Text>
            </Center>
          </Flex>
        </Flex>

        <Box
          display={{ base: 'none', md: 'block' }}
          overflowX="hidden"
          h="100%"
          w="50vw"
          position="absolute"
          right="0px"
        >
          <Box
            bgImage={signInImage}
            w="100%"
            h="100%"
            bgSize="cover"
            bgPosition="50%"
            position="absolute"
            borderBottomLeftRadius="20px"
          ></Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
