import { useNavigate } from 'react-router-dom';
// import { useContext, useState } from 'react';
// import moment from 'moment';
import {
  Box,
  Card,
  CardBody,
  Text,
  Button,
  Image,
  Stack,
  // useColorModeValue,
} from '@chakra-ui/react';

import { ReactComponent as Main } from '../assets/svg/main.svg';
// import { FaRegShareSquare } from 'react-icons/fa';
// import { ExternalLinkIcon } from '@chakra-ui/icons';
// import { getAuth } from 'firebase/auth';
// import { doc } from 'firebase/firestore';
// import {
//   getStorage,
//   ref,
//   uploadBytesResumable,
//   getDownloadURL,
// } from 'firebase/storage';

// import currencyFormat from '../services/currencyFormat';
// import DataContext from '../context/DataContext';
// import ShareModal from '../components/ShareModal';
// import { db } from '../constants';

function ListingCard({ listing }) {
  // const [liked, setLiked] = useState(false);
  // const { userData } = useContext(DataContext);
  // const auth = getAuth();

  // const safetyInput = useRef(null);
  const navigate = useNavigate();
  // const { isOpen, onOpen, onClose } = useDisclosure();
  // const [loading, setLoading] = useState(false);
  // const thisListingRef = doc(db, 'listings/', listing.uid);

  // const contactSeller = () => {
  //   // if (!isPosted) return;
  //   navigate('/connect/' + listing.uid + '/' + userData.uid);
  // };

  // const handleSafetyClick = () => {
  //   // safety test
  //   safetyInput.current.click();
  // };

  // const storeImage = async image => {
  //   return new Promise((resolve, reject) => {
  //     const storage = getStorage();
  //     const fileName = `vehicle-${Date.now()}`;

  //     const storageRef = ref(
  //       storage,
  //       'images/listings/' + listing.uid + '/' + fileName
  //     );

  //     const uploadTask = uploadBytesResumable(storageRef, image);

  //     uploadTask.on(
  //       'state_changed',
  //       snapshot => {
  //         // const progress =
  //         //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         // console.log('Upload is ' + progress + '% done');
  //         // switch (snapshot.state) {
  //         //   case 'paused':
  //         //     console.log('Upload is paused');
  //         //     break;
  //         //   case 'running':
  //         //     console.log('Upload is running');
  //         //     break;
  //         //   default:
  //         //     break;
  //         // }
  //       },
  //       error => {
  //         // console.log(error);
  //         reject(error);
  //       },
  //       () => {
  //         // Handle successful uploads on complete
  //         getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
  //           resolve(downloadURL);
  //         });
  //       }
  //     );
  //   });
  // };
  // console.log('listing', listing);
  // const onChangeFileInput = async e => {
  //   // console.log(e)
  //   setLoading(true);
  //   await Promise.resolve(storeImage(e.target.files[0]))
  //     .then(fileName => {
  //       console.log('upload done...', fileName);

  //       updateDoc(thisListingRef, {
  //         [e.target.id]: fileName,
  //       });
  //       // const newFormData = {
  //       //   ...formData,
  //       //   [e.target.id]: fileName,
  //       // };
  //       // setFormData(newFormData);
  //       // setListing(newFormData);
  //       // onClickSave(newFormData);
  //       setLoading(false);
  //     })
  //     .catch(err => {
  //       console.log('error', err);
  //       setLoading(false);
  //       // setLoadingImages({
  //       //   [e.target.id]: false,
  //       // });
  //       // toast({
  //       //   title: 'Could not upload image',
  //       //   status: 'error',
  //       //   isClosable: true,
  //       // });
  //       // if fail, return what was there before
  //       // return listing[e.target.id];
  //     });
  // };
  // const toDashboard = () => {
  //   navigate('/');
  //   onClose();
  // }
  return (
    <>
      <Card
        direction="row"
        overflow="hidden"
        // bg={useColorModeValue('gray.100', 'gray.900')}
        // variant="outline"
        // maxWidth={'500px'}
        borderWidth={1}
        borderColor="darkgrey"
        // minWidth={'450px'}
        my={2}
      >
        <Box pos="relative" maxW="35%" pl={2}>
          {listing.listingProfilePhoto &&
          typeof listing.listingProfilePhoto === 'string' ? (
            <Image
              src={listing.listingProfilePhoto}
              alt={listing?.uid}
              boxSize="full"
              width="80px"
              // maxW={{ base: '175px', sm: '175px' }}
              // h={{ base: '120px', sm: '120px' }}
              objectFit="contain"
            />
          ) : (
            <>
              <Main
                // width='80%'
                padding={5}
                width="80px"
                height="100%"
              />
            </>
          )}

          {/* <Image
            src={listing.listingProfilePhoto}
            alt={listing?.uid}
            boxSize="full"
            maxW={{ base: '100%', sm: '175px' }}
            maxH={{ base: '300px', sm: '200px' }}
            objectFit="contain"
          /> */}
          {listing.eezeID && (
            <Text
              pos="absolute"
              bottom="0%"
              left="100%"
              color="primary.800"
              // bgColor='whiteAlpha.200'
              bg="white"
              px={2}
              transform="translate(-100%,-0%)"
            >
              {listing.eezeID.toUpperCase()}
            </Text>
          )}
        </Box>

        <CardBody p={2}>
          <Text color="black" fontSize="lg">
            {listing?.title}
          </Text>
          <Stack direction="row" justify="space-between">
            <Box>
              <Text fontSize="lg">{listing?.sellerFullName}</Text>
              <Text>{listing?.sellerLocation}</Text>
            </Box>
            <Box>
              <Button
                px={5}
                colorScheme="primary"
                onClick={() => navigate(`/listing/${listing.uid}`)}
              >
                OPEN
              </Button>
            </Box>
          </Stack>
        </CardBody>
      </Card>
    </>
  );
}

export default ListingCard;
