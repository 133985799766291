import { useContext, useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertTitle,
  AspectRatio,
  Card,
  Center,
  Button,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Stack,
  Image,
  HStack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { EmailIcon } from '@chakra-ui/icons';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaRegPlayCircle } from 'react-icons/fa';
import { BsSearch, BsInfoCircle, BsListUl } from 'react-icons/bs';

import { db } from '../constants';
import DataContext from '../context/DataContext';
import SpinnerPage from '../components/SpinnerPage';
import Header from '../components/Header';
import AppBody from '../components/AppBody';
import InviteModal from '../components/InviteModal';
import InviteModalWarranty from '../components/InviteModalWarranty';
import InviteModalSafetyPromo from '../components/InviteModalSafetyPromo';
import InviteModalTransaction from '../components/InviteModalTransaction';
import logoImage from '../assets/logo.png';

function Homepage() {
  const [partnerData, setPartnerData] = useState(null);
  const toast = useToast();
  const { userData } = useContext(DataContext);
  const [queryParameters] = useSearchParams();
  // const { partnerUid, active } = userData;
  const partnerUid = userData?.partnerUid;
  const active = userData?.active;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenSafetyPromo,
    onOpen: onOpenSafetyPromo,
    onClose: onCloseSafetyPromo,
  } = useDisclosure();
  const {
    isOpen: isOpenWarranty,
    onOpen: onOpenWarranty,
    onClose: onCloseWarranty,
  } = useDisclosure();
  const {
    isOpen: isOpenTransaction,
    onOpen: onOpenTransaction,
    onClose: onCloseTransaction,
  } = useDisclosure();
  const {
    isOpen: isOpenVideo,
    onOpen: onOpenVideo,
    onClose: onCloseVideo,
  } = useDisclosure();
  // if (!userData) {
  //   // on first load, partnerData is not loaded yet
  //   // console.log('no datacontext')
  //   window.location.reload(false);
  // }
  // console.log('useContext(DataContext)')
  // console.log(useContext(DataContext))
  useEffect(() => {
    if (userData && !active) {
      const auth = getAuth();
      signOut(auth).then(() => {
        toast({
          title: 'Access denied',
          description: 'Membership inactive',
          status: 'warning',
          isClosable: true,
        });
        navigate('/login');
      });
    }
  }, []);

  useEffect(() => {
    if (partnerUid) {
      const unsubscribe = onSnapshot(doc(db, 'partners', partnerUid), doc => {
        setPartnerData(doc.data());
      });
      return unsubscribe;
    }
  }, [partnerUid]);

  useEffect(() => {
    if (partnerUid) {
      let ref;
      if (queryParameters.get('ref')) {
        ref = queryParameters.get('ref');
        // ex /?ref=garage1
      }
      let aref;
      if (queryParameters.get('aref')) {
        aref = queryParameters.get('aref');
        // ex /?aref=affiliate1
      }
      if (queryParameters.get('a')) {
        aref = queryParameters.get('a');
        // ex /?a=affiliate1
      }
      let iref;
      if (queryParameters.get('iref')) {
        iref = queryParameters.get('iref');
        // ex /?iref=influencer1
      }
      if (queryParameters.get('i')) {
        iref = queryParameters.get('i');
        // ex /?i=influencer1
      }
      // add ref, aref or iref to user
      if (ref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          garageRef: ref,
        });
      }
      if (aref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          affiliateRef: aref,
        });
      }
      if (iref) {
        updateDoc(doc(db, 'partners', partnerUid), {
          influencerRef: iref,
        });
      }
    }
  }, [partnerUid, queryParameters]);

  const navigate = useNavigate();

  if (!partnerData) {
    return <SpinnerPage />;
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <InviteModal />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onClose}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenSafetyPromo} onClose={onCloseSafetyPromo}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalSafetyPromo />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onCloseSafetyPromo}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenWarranty} onClose={onCloseWarranty}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalWarranty />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onCloseWarranty}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenTransaction} onClose={onCloseTransaction}>
        <ModalOverlay />
        <ModalContent>
          <InviteModalTransaction />
          <ModalFooter>
            <Button
              w="full"
              variant="outline"
              colorScheme="primary"
              onClick={onCloseTransaction}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isOpenVideo} onClose={onCloseVideo} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <AspectRatio ratio={16 / 9}>
            {/* <iframe
    title='Partner Functions'
    src='https://www.youtube.com/embed/0fCAT9425J4'
    allowFullScreen
  /> */}
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/0fCAT9425J4?si=kalZLwTGbBrJeTeT&autoplay=1"
              title="Partner Functions"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            />
          </AspectRatio>

          <ModalFooter>
            <Button
              w="full"
              variant="link"
              colorScheme="primary"
              onClick={onCloseVideo}
            >
              CLOSE
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Header />

      <AppBody>
        <Stack p={0} spacing={2}>
          <Card
            align="center"
            shadow="xl"
            textAlign="center"
            variant="outline"
            p={5}
          >
            <Text
              fontSize="2xl"
              lineHeight={1.2}
              fontWeight="bold"
              bgGradient="linear(to-l, primary.800,primary.300)"
              bgClip="text"
            >
              Welcome to the
            </Text>
            <Center>
              <Image
                src={logoImage}
                alt="Logo"
                width="40%"
                maxW={'500px'}
                objectFit="cover"
              />
            </Center>
            <Text
              fontSize="2xl"
              lineHeight={1.2}
              fontWeight="bold"
              bgGradient="linear(to-l, primary.800,primary.300)"
              bgClip="text"
              // mb={5}
            >
              Partner Program
            </Text>
            <Text fontSize="lg" as="b" mt={2}>
              {partnerData?.businessName}
            </Text>
            <Text as="i" mt={2} color="primary.800">
              Extend dealer-level services to your customers and access new
              revenue streams by offering EEZE products
            </Text>

            <Button
              colorScheme="primary"
              variant="outline"
              size="sm"
              onClick={onOpenVideo}
              mt={2}
              leftIcon={<FaRegPlayCircle size="25px" p={2} />}
            >
              Watch Walkthrough Video
            </Button>

            {!partnerData?.approved && (
              <>
                <Alert
                  status="info"
                  variant="subtle"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                  mt={2}
                  // height='200px'
                >
                  {/* <AlertIcon boxSize="30px" mr={0} /> */}
                  <AlertTitle mt={4} mb={1} fontSize="lg">
                    Thank you for signing up!
                  </AlertTitle>
                  <AlertDescription maxWidth="md" mt={3}>
                    {/* Your business profile is being reviewed by the team.  */}
                    A member of the team will contact you to finalize. Certain
                    functions may not work until approval.
                    {/* Thanks for submitting your application. Our team will get back to you soon. */}
                  </AlertDescription>
                  {/* <Divider borderWidth={1} borderColor='lightgrey'/> */}
                  <AlertDescription maxWidth="md" mt={3}>
                    Please add/review the information provided:
                  </AlertDescription>
                  <Button
                    onClick={() => navigate('/profile')}
                    bgColor={'black'}
                    color={'white'}
                    // size="lg"
                    w="150px"
                    my={2}
                    leftIcon={<BsInfoCircle />}
                  >
                    Profile
                  </Button>
                </Alert>
              </>
            )}
          </Card>

          <Card shadow="xl" bgColor="white" variant="outline" p={5}>
            <HStack justify="space-between">
              <Text align="center" flex={1}>
                View all referred customers and vehicles
              </Text>

              <Button
                onClick={() => navigate('/referrals')}
                colorScheme="primary"
                size="lg"
                w="150px"
                leftIcon={<BsListUl />}
              >
                View
              </Button>
            </HStack>
          </Card>

          <Card shadow="xl" bgColor="white" variant="outline" p={5}>
            <HStack justify="space-between">
              <Text align="center" flex={1}>
                Send a link to a customer to claim their <b>FREE PowerTrain Warranty</b>
              </Text>

              <Button
                onClick={onOpenSafetyPromo}
                colorScheme="primary"
                leftIcon={<EmailIcon />}
                size="lg"
                w="150px"
              >
                Invite
              </Button>
            </HStack>
          </Card>

          <Card shadow="xl" bgColor="white" variant="outline" p={5}>
            <HStack justify="space-between">
              <Text align="center" flex={1}>
              Share a referral link to a customer to purchase an <b>EEZE Extended Warranty</b> 
              </Text>

              <Button
                onClick={onOpenWarranty}
                colorScheme="primary"
                leftIcon={<EmailIcon />}
                size="lg"
                w="150px"
              >
                Invite
              </Button>
            </HStack>
          </Card>

          <Card shadow="xl" bgColor="white" variant="outline" p={5}>
            <HStack justify="space-between">
              <Text align="center" flex={1}>
                Share a referral link to a customer to <b>Get Verified with EEZE</b>
              </Text>

              <Button
                onClick={onOpenTransaction}
                colorScheme="primary"
                leftIcon={<EmailIcon />}
                size="lg"
                w="150px"
              >
                Invite
              </Button>
            </HStack>
          </Card>

          <Card shadow="xl" bgColor="white" variant="outline" p={5}>
            <HStack justify="space-between">
              <Text align="center" flex={1}>
                Search for a vehicle listing to upload relevant documents
              </Text>

              <Button
                onClick={() => navigate('/listings')}
                colorScheme="primary"
                size="lg"
                w="150px"
                // disabled={!partnerData?.approved}
                leftIcon={<BsSearch />}
              >
                Search
              </Button>
            </HStack>
          </Card>

          <br />
          <br />
          <br />
          <br />
        </Stack>
      </AppBody>
    </>
  );
}

export default Homepage;
